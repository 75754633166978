












































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue')
  }
})
export default class InsNotification extends Vue {
  multipleSelection: any = [];
  ShowUnRead: boolean = false;
  currentPage: number = 1;
  pagesize: number = 5;
 checkAll:boolean= false;
  tableHeader: any = {
    background: '#eef1f6',
    color: '#606266'
  };
  allMessage:any[]=[];
  mPager = {
    Condition: {
      MemberId: '',
      QueryBgDtStr: '',
      QueryEdDtStr: '',
      Url: '',
      Sku: '',
      OrderId: undefined,
      IsRead: undefined,
      SendType: undefined
    },
    PageInfo: {
      Page: 1,
      PageSize: 100
    }
  };
MessageQty:string='';
TotalNum:string='';
strId:any='';
selectChange (id) {
  console.log(id);
}
getMsgQty () {
  let that = this;
  this.$Api.messageApi.getUnreadMsgQty().then((result) => {
    that.MessageQty = result.data.ReturnValue;
  });
}
getAllMessage () {
  let that = this;
  this.$Api.messageApi.getAllMsgList(that.mPager).then((result) => {
    this.$HiddenLayer();
    that.allMessage = result.data.ReturnValue.Data;
    that.TotalNum = result.data.ReturnValue.Data.length;
  });
}
markMsgAsRead (id) {
  let that = this;
  this.strId += id + ',';
}
changeStatus () {
  this.$Api.messageApi.markedMessageAsRead(this.strId).then((result) => {
    if (result) {
      this.$message({
        message: '保存成功',
        type: 'success'
      });
      this.getAllMessage();
    }
  });
}

mounted () {
  this.getAllMessage();
  this.getMsgQty();
}
IsReadFilter (row, column) {
  return row.IsRead === false ? '未读' : '已读';
}
filterTag (value, row) {
  return row.IsRead === value;
}
currentChange (currentPage) {
  // 改变当前页
  this.currentPage = currentPage;
}
// 点击上一页和下一页的时事件
handleSizeChange (psize) {
  this.pagesize = psize;
}
}
